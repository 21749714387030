<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Report Daily</h3>
          </div>
          <div class="panel-body p-0">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [20, 40, 80, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columns"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>

                  <export-excel
                    :data   = "rows">
                    <button
                    type="button"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-print"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>               
                </export-excel>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'maintain'">
                  {{ props.row.type_ticket }} Maintenance
                </span>

                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      class="btn btn-icon btn-success m-r-5"
                      v-on:click="
                        getDetail(props.row.type_ticket, props.row.id_ticket)
                      "
                    >
                      <i class="fa fa-search"></i>
                    </button>

                    <button 
                      class="btn btn-icon btn-dark m-r-5"
                      v-on:click="postDataTime(props.row)"
                    >
                      <i class="fa fa-clock" ></i>
                    </button>

                    <router-link
                      v-if="props.row.status == 'Close' && props.row.activity !== 'Preventive' "
                      :to="'/ticket/report/' + props.row.id_ticket"
                      class="btn btn-icon btn-info m-r-5"
                      target="_blank"
                    >
                      <i class="fa fa-file"></i>
                    </router-link>


                    <router-link
                      v-if="props.row.status == 'Close' && props.row.activity == 'Preventive' && props.row.method == 'ONSITE'"
                      :to="'/ticket/report/' + props.row.id_ticket"
                      class="btn btn-icon btn-info m-r-5"
                      target="_blank"
                    >
                      <i class="fa fa-file"></i>
                    </router-link>

                    
                    <router-link
                      v-if="props.row.status == 'Close' && props.row.activity == 'Preventive' && props.row.method == 'REMOTE' "
                      :to="'/ticket/remote/' + props.row.id_ticket"
                      class="btn btn-icon btn-info m-r-5"
                      target="_blank"
                    >
                    <i class="fa fa-desktop"></i>
                  </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Detail Ticket</h5>
      </template>
      <div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td style="width: 110px">No Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.no_ticket }}</td>
            </tr>
            <tr>
              <td>Subject Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.subject_ticket }}</td>
            </tr>
            <tr>
              <td>Fill Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.fill_ticket }}</td>
            </tr>
            <tr>
              <td>Device</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.nama_device }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <button
          @click="closeModal"
          class="btn btn-block btn-info"
          variant="primary"
        >
          Close
        </button>
      </template>
    </b-modal>


    <b-modal v-model="modalVisibleTime" @hide="beforeModalCloseTime">
      <template #modal-header>
        <h5 class="modal-title">Edit Time Ticket</h5>
      </template>
      <div class="row">
        <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >Start Time
              </label>
              <input
                v-model="time.start"
                type="datetime-local"
                class="form-control"
                placeholder="Input Start Time"
                required
                :class="{
                  'is-valid': time.start != '',
                  'is-invalid': time.start == '',
                }"
              />
              <!-- <date-picker v-model="time.start" :config="options"></date-picker> -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >End Time
              </label>
              <input
                v-model="time.end"
                type="datetime-local"
                class="form-control"
                placeholder="Input End Time"
                required
                :class="{
                  'is-valid': time.end != '',
                  'is-invalid':time.end == '',
                }"
              />
            </div>
          </div>
        </div>
      <template #modal-footer>
        
        <button
          @click="closeModalTime"
          class="btn btn-info"
          variant="primary"
        >
          Close
        </button>

        <button
          class="btn btn-success"
          @click="postDataTimeAction"
          variant="primary"
          :disabled="isLoadingTime"
        >
          <i v-if="isLoadingTime" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import userData from "../../../plugins/userData";
import deleteData from "@/plugins/deleteData";
import paginations from "@/plugins/paginations";
import formatting from "@/plugins/formatting";
import Swal from "sweetalert2";


export default {
  mixins: [userData, deleteData, paginations, formatting],
  data() {
    return {
      columns: [
      {
          label: "Number",
          field: "no_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Date",
          field: "date",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Request By",
          field: "request_by",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Area",
          field: "area",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Lokasi",
          field: "lokasi",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Sublokasi",
          field: "sublokasi",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Activity",
          field: "activity",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Issue",
          field: "issue",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Problem",
          field: "problem",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Equipment",
          field: "equipment",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Progress",
          field: "ticket_progress",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Remarks",
          field: "note_k",
          width: "300px",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Time Reported",
          field: "time_reported",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Response Time",
          field: "response_date",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Process Time",
          field: "process_date",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Finish Time",
          field: "verify_date",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Time Start",
          field: "time_start",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Time End",
          field: "time_end",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Duration",
          field: "duration",
          sortable: false, 
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Completed Time",
          field: "finish_date",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Team",
          field: "team",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Technician",
          field: "technician",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },

        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      id: null,
      tableInfo: "Loading data",
      isLoading: false,
      isLoadingTime: false,
      time:{
        start:'',
        end:''
      },
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 20,
      },
      filter: {
        district: "",
        village: "",
      },
      detail: {
        no_ticket: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      districtList: [],
      villageList: [],
      modalVisible: false,
      conditionMet: false,
      modalVisibleTime: false,
      conditionMetTime: false,
    };
  },
  mounted() {
    this.fetchData();
    console.log(this.userLevel);
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/report/daily/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    getDetail(type, id) {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/ticket/detail/" + id, {
          headers: {
            token: this.userToken,
          },
          params: {
            type: type,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.showModal();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postDataTime(detail) {
      this.id = detail.id_ticket;
      this.time.start = detail.process_date;
      this.time.end = detail.verify_date;
          this.showModalTime();
    },
    postDataTimeAction() {
      this.isLoading = true;
      const formData = new FormData();
        formData.append("start", this.time.start);
        formData.append("id_ticket", this.id);
        formData.append("end", this.time.end);
      axios
        .post(this.$url+"/v2/ticket/changeTime/" ,formData, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          console.log(response);
          Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.fetchData();
                this.closeModalTime();
              }
            }, 700);
          
          
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },

    showModalTime() {
      this.modalVisibleTime = true;
      this.conditionMetTime = false;
    },
    closeModalTime() {
      this.modalVisibleTime = false;
      this.conditionMetTime = true;
    },
    beforeModalCloseTime(evt) {
      if (!this.conditionMetTime) {
        evt.preventDefault();
      }
    },

  },
};
</script>